<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                    label="İsim"
                    horizontal
                    v-model="data.name"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="2"><label>Kampanya Tipi</label></CCol>
              <CCol>
                <Multiselect 
                    v-model="data.adcampType"
                    :options="adcampTypes" 
                    :multiple="true" 
                    selected-label="Seçildi"
                    deselect-label="Kaldır"
                    :close-on-select="false" 
                    placeholder="Kampanya Tipi" 
                    track-by="value" 
                    label="label"
                    
                >
                    <span slot="noResult">Dikkat! Aramanıza uygun kayıt bulunamadı.</span>
                </Multiselect>
                </CCol>
            </CRow>

            <CRow>
              <CCol>
                <CInput
                    label="Oluşturma Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startTime"
                />
              </CCol>
              <CCol>
                <CInput
                    label="Oluşturma Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
  
  import Multiselect from 'vue-multiselect';

   export default{
       name: "AdCampFilter",
       components:{ Multiselect },
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
       computed:{
            adcampTypes: function(){
              let data = []
              this.$store.getters.adcampTypes.map( type => data.push({label: type, value: type}) );
              return data
            },
        },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>